.Feedback-div,
.hire-div {
  background-color: #1863c9 !important;
  background: url(../Images/bg-top.png), url(../Images/bg-bottom.png);
  background-position: left top, right bottom;
  background-repeat: no-repeat;
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-prev,
.carousel-control-next {
  display: none !important;

}

.testimonial-item,
.hire-item {
  text-align: left;
  line-height: 150%;
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 45px;
  padding-right: 40px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  /* display: inline-block; */
}

.products .hire-item .row {
  margin-top: -15px !important;
  color: #1863c9;
  font-weight: bolder;
  display: inline !important;
  width: 40%;
  float: right !important;
  margin-bottom: 10px;
}

.hire-item .row span:hover {
  /* margin-top: 70px !important; */
  color: #0aac47;
  font-weight: bolder;

}

.quote_icon {
  margin-bottom: 15px;
  font-size: xx-large;
  color: #1863c9;
}

.testimonial-item h4 {
  margin-top: 1rem;
  margin-left: 70px;
  color: black;

}

.hire-item h4 {
  margin-top: 1rem;

  color: black;

}

.testimonial-item p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
  line-height: 25px;
}

.hire-item p {
  /* font-size: 1rem; */
  color: #666;
  margin-bottom: 1rem;
  line-height: 25px;
  /* text-align: justify; */
}

@media screen and (max-width:600px) {
  .hire-item p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.prof {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* arrow button  */
.mybtn {
  position: relative;
  width: 190px !important;
  height: 45px;
  color: white;
  border: 2px solid #1863c9;
  border-radius: 50px;
  background-color: #1863c9;
  z-index: 1;
}

.circle {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50px;
  left: 0;
  top: 0;
  z-index: 2;
  transition: .5s cubic-bezier(1, 0, .8, 1);
}

.mybtn:hover .circle {
  width: 100%;
}

.arrow {
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: solid black 3px;
  border-right: 3px solid black;
  top: 14px;
  transform: rotate(45deg);
  left: 10px;
  transition: .5s
}

.arrow:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: black;
  transform: rotate(-45deg);
  top: 3px;
  left: 0;
  opacity: 0;
  transition: .5s;
}

.mybtn:hover .arrow:before {
  opacity: 1;
}

.text {
  margin-left: 50px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
  z-index: 3;
  transition: .5s;
}

.mybtn:hover .text {
  color: black;
}

/* ends */
.client_img {
  position: absolute;
  border-radius: 50px;
  right: 0;
    top: -2%;
}

.fadeInUp {
  visibility: visible;
  animation-delay: 0.1s;
  animation-name: fadeInUp;
}

.product-section .mybtn {
  width: 35% !important;
}

@media screen and (max-width:800px) {
  .client_img {
    position: static;
  }
}
  
@media screen and (max-width:600px) {
 .img-fluid {
   height: 250px !important;
 }
  .product-section .mybtn {
    width: 45% !important;
  }
 .products .hire-item .row {
 width: 90%;
 }
      
}