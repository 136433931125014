.service-count h1,h2{
    color: #1863c9;
}
.career .service-item:hover p{
    color: white;
}
.service-div{
    /* border:  thin solid red; */
    background-color: white;
}
/* .bg-img{
    background-image: url('./../Images/banner-image.png');
} */
.S-section-title{
    /* border: thin solid red; */
    position: relative !important;
}

.title{
    font-weight: bold;
    color: #1863c9 !important;
    position: relative; 
    display: inline; 
}

.Service-sub-title{
    padding-top: 10px;
    font-weight: bold;
    color: #1D1D27;
}

.S-section-title.text-center::before {
    left: 50%;
    margin-left: -25px;
}

.S-section-title.text-center::after {
    left: 50%;
    margin-left: 25px;
}

.S-section-title.text-center h6::before, .S-section-title.text-center h6::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    top: 2px;
    left: 0;
    background: rgba(33, 66, 177, .5);
}

.S-section-title.text-center h6::after {
    top: 5px;
    left: 3px;
}
 .card-text{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
/* .S-section-title::before {
    position: absolute;
    content: "";
    width: 45px;
    height: 3.5px;
    bottom: 0;
    left: 0;
    background: #1d1d27;
} */

/* .S-section-title::after {
    position: absolute;
    content: "";
    width: 4.5px;
    height: 3.5px;
    bottom: 0;
    left: 50px;
    background: #1d1d27;
} */

.fadeInUp{
    visibility: visible;
    animation-delay: 0.1s;
    animation-name: fadeInUp;
}

.service-item{
    height: 350px !important;
    background: #FFFFFF;
    box-shadow: 0 0 30px rgba(0, 0, 0, .10);
    transition: .5s;
    border: none !important;
    border-radius: 10px !important;
    text-align: center;
    flex-direction: column !important;
    display: flex !important;
    padding: 30px 25px;
}

.service-item:hover{
    background-color: #1863c9 !important;

    .service-img{
    background: url(../Images/icon-shape-white.png) center center no-repeat;
    color: #1863c9; 
    }
    .card-title{
        color: white !important;
    }
    .card-text{
        color: white !important;
    }
}

.card-body{
    padding: 0 !important;
}

.service-img{
    height: 90px;
    display: flex;
    background: url(../Images/icon-shape-primary.png) center center no-repeat;
    transition: .5s;
    align-items: center;
    font-size: 35px;
    color: white;
    margin: 0 auto 20px auto;
}

.service-icon{
    margin: 0 auto;
}

.card-title{
    color: black !important;
}

.card-text{
    padding-left: 5px;
    padding-right: 5px;
    line-height: 25px;
    color: #888;
}

.rad-mor{
    position: relative;
    background-color: transparent !important;
    border: none !important;
    color:#1863c9 !important;
    font-size: 17px !important;
    display: flex;
    font-weight: 500  !important;
    transition: .5s;
    z-index: 1;
}

.service-item .rad-mor::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    top: 0;
    left: 0;
    border-radius: 35px;
    background: #DDDDDD;
    transition: .5s;
    z-index: -1;
}

.service-item:hover .rad-mor::before {
    position: absolute;
    content: "";
    width: 105px;
    height: 35px;
    top: 0;
    left: 0;
    border-radius: 35px;
    background: #ffffff;
    transition: .5s;
    z-index: -1;
}