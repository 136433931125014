.contact {
   /* height: 90vh; */
   /* display: flex; */
}

.contact h1 {
   color: #1863c9;
}

.contact span,
input::placeholder {
   color: #0aac47;
}

h3,
label {
   margin: 10px 0;
   color: #1863c9 !important;
}

.contact h1 span {
   color: #0aac47;
}

.contact .leftSide {
   flex: 50%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

.contact .rightSide {
   flex: 50%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   padding-left: 5%;
   padding-right: 5%;
}

.contact .rightSide h1 {
   font-size: 50px;
}

form {
   display: flex;
   flex-direction: column;
}

form label {
   margin-top: 5%;
   color: grey;
}

form input,textarea {
   margin-top: 1%;
   width: 100%;
   border: none;
   border: 1px solid grey;
   color: black;
}

form input {
height: 40px;
}



input::placeholder,
textarea::placeholder {
   font-weight: lighter;
   color: grey;
   font-family: Arial, Helvetica, sans-serif;
}

form input:focus,
textarea:focus {
   outline: none;
}

.contact button {
   border: none;
   border-radius: 5px;
   cursor: pointer;
   justify-content: center;
   background-color: #1863c9;
   height: 50px;
   width: 180px;
   color: white;
   text-decoration: none;
   font-size: 20px;
}

.contact button:hover {
   background-color: #014db6;
   cursor: pointer;
   transition: 0.3s ease-in;
}

@media only screen and (max-width: 600px) {
   .contact {
      flex-direction: column;
   }

   .rightSide #formButton {
      margin-bottom: 5%;
   }
}

@media only screen and (max-height: 800px) {
   .contact .leftSide {
      display: none;
   }
}