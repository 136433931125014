

.Team-div{
    /* border:  thin solid red; */
    background-color: white;
}

.T-section-title{
    /* border: thin solid red; */
    position: relative !important;
}

.title{
    font-weight: bold;
    color: #1863c9 !important;
    position: relative; 
    display: inline; 
}

.Team-sub-title{
    padding-top: 10px;
    font-weight: bold;
    color: #1D1D27;
}

.T-section-title.text-center::before {
    left: 50%;
    margin-left: -25px;
}

.T-section-title.text-center::after {
    left: 50%;
    margin-left: 25px;
}

.T-section-title.text-center h6::before, .T-section-title.text-center h6::after {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    top: 2px;
    left: 0;
    background: rgba(33, 66, 177, .5);
}

.T-section-title.text-center h6::after {
    top: 5px;
    left: 3px;
}

.T-section-title::before {
    position: absolute;
    content: "";
    width: 45px;
    height: 3.5px;
    bottom: 0;
    left: 0;
    background: #1d1d27;
}

.T-section-title::after {
    position: absolute;
    content: "";
    width: 4.5px;
    height: 3.5px;
    bottom: 0;
    left: 50px;
    background: #1d1d27;
}

.fadeInUp{
    visibility: visible;
    animation-delay: 0.1s;
    animation-name: fadeInUp;
}

.team-item{
    position: relative;
    transition: .5s;
    z-index: 1;
    overflow: hidden;
}

.team-item::after {
    position: absolute;
    content: "";
    top: 3rem;
    right: 3rem;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .1);
    transition: .5s;
    z-index: -1;
}


.team-socialIcon{
    width: 60px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    display: flex !important;
    margin-top: 3rem;
    padding-top: 1.5rem;
}

.T-socialM-icon{
  text-align: center;
    transition: .5s !important;
    color: #1863c9  !important;
    border: thin solid #1863c9 !important;
    background-color: #ffffff !important;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 15px;
}
.T-socialM-icon:hover{
    background-color: #07ac46 !important;
    color: white !important;
    border-color: #07ac46 !important;
}
.T-socialM-icon svg{
    height: 2rem;
    width: 2rem;
}
.team_img{
    border-radius: 10px !important;
    /* border: thin solid red; */
    height: 404px;
}

.img_and_icon{
    display: flex;
}

.teamEmp_name{
    color: black;
    margin-bottom: 4px;
    transition: .5s;
}
.team-item p {
 display:  block;
}
.team-item:hover::after  {
    /* background-color: #07ac46; */
}

.team-item:hover .teamEmp_name {
    color: #FFFFFF !important;
}

.team-item:hover .desing{
    color: #FFFFFF !important;
    transition: .5s;
}
