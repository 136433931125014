.Navbar{
    /* position: absolute !important; */
    width: 100%;
    padding: 0 !important;  
    top: 0 !important;
    left: 0;
    /* border: thin solid red; */
    border-bottom: 1px solid rgba(256, 256, 256, .1);
    z-index: 999 !important;
    transform: .5s;
}   

.brand{
    margin-right: 0 !important;
}

.brand-link{
    text-decoration: none !important;
}

.brandicon{
    margin-right: 5px;
    margin-top: -15px;
    font-size: 40px;
    color: white;
}

.brandname{
    display: inline;
    font-weight: bold;
    color: white;
}

.navbar-nav .nav-link{
    position: relative;
    margin-left: 5px;
    padding: 37.3px 0;
    outline: none;
    transition: .5s;
    letter-spacing: 0.5px;
    /* color: black !important; */

}

.navbar-nav .nav-link::before {
    position: absolute;
    left: 50%;
    width: 0;
    content: "";
    height: 2px;
    bottom: -1px;
    background-color: #1863c9;
    transition: .5s;
}


.navbar-light .navbar-nav .nav-link:hover::before{
    width: 100%;
    left: 0;
}

.navbar-nav .nav-link:hover {
    color: #1863c9 !important;
}

.myactive {
    color: #1863c9 !important;
    border-bottom: #1863c9 1px solid !important;
}

@media (max-width: 992px) {
    .navBody .nav-link{
    margin: 0;
    padding: 15px;
    color: black !important;
    }
    .navBody .nav-link:hover{
        color: #1863c9 !important;
        background-color: rgb(240, 240, 240);
    }
    .Navbar{
        position: unset !important;
        background-color: white !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .brandicon, .brandname{
        color: #1863c9 !important;
    }
    .sticky-nav{
        transform: .5s !important;
        padding-top:8px !important;
        padding-bottom:8px !important;
    }
}

.navLink5{
    margin-right: 15px;
}

.drop-link:hover{
    background-color: #1863c9 !important;
    color: white !important;
}

.dropdown-menu{
    margin-top: -10px !important;
    border-radius: 3px !important;
}

.dropdown-menu::before{
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  margin: -15px 0 0 30px;
}