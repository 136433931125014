.Hero_div .img-col{
    margin-top: 0 !important;
    margin-left: 50px;
}
.Hero-img{
    width:80% !important;
 
}
@media (max-width: 992px) {
    .text-col{
        flex: 0 0 auto !important;
    }
    .img-col{
        flex: 0 0 auto !important;
    }
}

.Hero_div{
    background-color: #1863c9 !important;
    background-image: url(../Images/bg-dot.png), url(../Images/bg-dot.png), url(../Images/bg-round.png), url(../Images/bg-tree.png), url(../Images/bg-bottom-hero.png);
    background-position: 10px 10px, bottom 190px right 10px, left 55% top -1px, left 45% bottom -1px, center bottom -1px;
    background-repeat: no-repeat;
}

.hero-heading{
    color: white !important; 
    font-weight: 700;
}

.hero-sub{
    line-height: 25px;
    font-size: 16px;
    color:  white;
    
}

.Quote-Button{
    /* display: inline-block !important; */
    /* padding: 15px 45px 15px 45px !important; */
    color: rgb(0, 0, 0) !important;
    background-color: white !important;
    border-color: #F7FAFF !important;
    border-radius:  50rem !important;
    margin-right: 15px;
    transition: 0.5s !important;


}

.Quote-Button:hover{
    color: #1863c9 !important;
}
.Contact-Button {
    /* display: inline-block !important; */
    /* padding: 15px 45px 15px 45px !important; */
    color: rgb(255, 255, 255) !important;
    border-color: #F7FAFF !important;
    border-radius:  50rem !important;
}
.Contact-Button:hover{
    color: black !important;
    transition: 0.5s;
}


.zoomIn {
    animation-duration: 1s;
    animation-name: zoomIn;
}
    
.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.slideInLeft {
    animation-duration: 1s;
    animation-name: slideInLeft;
}

.slideInRight {
    animation-duration: 1s;
    animation-name: slideInRight;
}
@media screen and (max-width:600px) {
    .Hero_div .img-col {
        margin: 0 !important;
 
    }
  }
